import React from "react";
import { Controller } from "react-hook-form";
import InputLm from "pages/admin/cliente/projetos/subparte/components/input-lm";
import {
    proximaCelula,
    TooltipErro,
} from "pages/admin/cliente/projetos/subparte/components/utils";

export default function TabelaPorcas({
    fieldPorcas,
    preenchimento,
    campoRevisao,
    form,
}) {
    const { control, watch, setValue } = form;

    const deletarLinha = (e, index) => {
        e.preventDefault();

        const fieldset = e.target.closest("fieldset");
        if (fieldset && fieldset.disabled) {
            return;
        }

        let confirmacao = window.confirm("Deseja deletar a linha selecionada?");
        if (!confirmacao) {
            return;
        }

        if (fieldPorcas.fields.length > 1) {
            fieldPorcas.remove(index);
        } else {
            alert("Não é possível remover a última linha");
        }
    };

    return (
        <table className="table-fixed">
            <thead className="header-sticky">
                <tr>
                    <th
                        colSpan={1}
                        className="bg-blue-600 text-white border border-gray-900 font-medium"
                    >
                        PORCAS
                    </th>
                    <th colSpan={3} />
                </tr>
                <tr>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Bitola
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Quantidade
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Qualidade
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Revisão
                    </th>
                </tr>
            </thead>
            <tbody>
                {fieldPorcas.fields.map((porca, index) => {
                    let autoPreenchimento = porca.flag_auto_preenchimento;
                    if (!autoPreenchimento) {
                        return <React.Fragment key={porca.id} />;
                    }

                    return (
                        <tr key={porca.id} className="auto-preenchimento">
                            <td className="flex flex-row">
                                <InputLm
                                    defaultValue={porca.dimensao1}
                                    disabled={true}
                                    type="text"
                                    className="text-center w-full input-tabela-sm auto-preenchimento "
                                />
                            </td>
                            <td>
                                <InputLm
                                    defaultValue={porca.quantidade}
                                    disabled={true}
                                    type="text"
                                    className="text-center w-full input-tabela-sm auto-preenchimento "
                                />
                            </td>
                            <td>
                                <InputLm
                                    defaultValue={porca.qualidade}
                                    disabled={true}
                                    type="text"
                                    className="text-center w-full input-tabela-sm auto-preenchimento "
                                />
                            </td>
                            <td>
                                <InputLm
                                    defaultValue={porca.rev}
                                    disabled={true}
                                    type="text"
                                    className="text-center w-full input-tabela-sm auto-preenchimento "
                                />
                            </td>
                        </tr>
                    );
                })}

                {fieldPorcas.fields.map((porca, index) => {
                    let autoPreenchimento = porca.flag_auto_preenchimento;
                    if (autoPreenchimento) {
                        return <React.Fragment key={porca.id} />;
                    }

                    return (
                        <tr
                            key={porca.id}
                            className={porca.flag_erro ? " erro " : ""}
                        >
                            <td className="flex flex-row">
                                <TooltipErro item={porca} />
                                <Controller
                                    name={`porcas.${index}.dimensao1`}
                                    control={control}
                                    defaultValue={porca.dimensao1}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onChange={(e) => {
                                                let value = String(
                                                    e.target.value,
                                                ).toUpperCase();
                                                const simbolos = [
                                                    "-",
                                                    "+",
                                                    ".",
                                                ];

                                                for (let simbolo of simbolos) {
                                                    if (value === simbolo) {
                                                        e.preventDefault();

                                                        let parafuso =
                                                            preenchimento
                                                                ?.parafusos[
                                                                simbolos.indexOf(
                                                                    simbolo,
                                                                )
                                                            ];
                                                        if (!parafuso) {
                                                            continue;
                                                        }

                                                        let bitola =
                                                            parafuso.parafuso_bitola;
                                                        let qualidade =
                                                            parafuso.porca_qualidade;
                                                        setValue(
                                                            `porcas.${index}.dimensao1`,
                                                            bitola,
                                                        );
                                                        setValue(
                                                            `porcas.${index}.qualidade`,
                                                            qualidade,
                                                        );
                                                        proximaCelula(e);
                                                        return;
                                                    }
                                                }

                                                setValue(
                                                    `porcas.${index}.dimensao1`,
                                                    value,
                                                );
                                                let parafuso =
                                                    preenchimento?.parafusos?.find(
                                                        (x) =>
                                                            x.parafuso_bitola ===
                                                            value,
                                                    );
                                                let qualidade =
                                                    parafuso?.porca_qualidade;

                                                if (!parafuso) {
                                                    return;
                                                }
                                                if (qualidade) {
                                                    setValue(
                                                        `porcas.${index}.qualidade`,
                                                        qualidade,
                                                    );
                                                }
                                                proximaCelula(e);
                                            }}
                                            onBlur={(e) => {
                                                if (
                                                    index ===
                                                        fieldPorcas.fields
                                                            .length -
                                                            1 &&
                                                    e.target.value !== ""
                                                ) {
                                                    fieldPorcas.append(
                                                        {},
                                                        { shouldFocus: false },
                                                    );
                                                }
                                            }}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            disabled={autoPreenchimento}
                                            type="text"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`porcas.${index}.quantidade`}
                                    control={control}
                                    defaultValue={porca.quantidade}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            required={
                                                !!watch(
                                                    `porcas.${index}.dimensao1`,
                                                )
                                            }
                                            min={
                                                !!watch(
                                                    `porcas.${index}.dimensao1`,
                                                )
                                                    ? 1
                                                    : ""
                                            }
                                            type="text"
                                            soNumero={true}
                                            disabled={autoPreenchimento}
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`porcas.${index}.qualidade`}
                                    control={control}
                                    defaultValue={porca.qualidade}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            required={
                                                !!watch(
                                                    `porcas.${index}.dimensao1`,
                                                )
                                            }
                                            disabled={autoPreenchimento}
                                            type="text"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`porcas.${index}.rev`}
                                    control={control}
                                    defaultValue={porca.rev}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            disabled={
                                                !campoRevisao ||
                                                autoPreenchimento
                                            }
                                            type="text"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
