import { Controller } from "react-hook-form";
import React from "react";
import {
    proximaCelula,
    TooltipErro,
} from "pages/admin/cliente/projetos/subparte/components/utils";
import InputLm from "pages/admin/cliente/projetos/subparte/components/input-lm";

export default function TabelaCalcos({
    fieldCalcos,
    preenchimento,
    campoRevisao,
    form,
}) {
    const { control, getValues, watch, setValue } = form;

    const deletarLinha = (e, index) => {
        e.preventDefault();

        const fieldset = e.target.closest("fieldset");
        if (fieldset && fieldset.disabled) {
            return;
        }

        let confirmacao = window.confirm("Deseja deletar a linha selecionada?");
        if (!confirmacao) {
            return;
        }

        if (fieldCalcos.fields.length > 1) {
            fieldCalcos.remove(index);
        } else {
            alert("Não é possível remover a última linha");
        }
    };

    return (
        <table className="table-fixed">
            <thead className="header-sticky">
                <tr>
                    <th
                        colSpan={1}
                        className="bg-blue-600 text-white border border-gray-900 font-medium"
                    >
                        CALÇOS
                    </th>
                    <th colSpan={4} />
                </tr>
                <tr>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Bitola
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Espessura
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Quantidade
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Qualidade
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Revisão
                    </th>
                </tr>
            </thead>
            <tbody>
                {fieldCalcos.fields.map((calco, index) => {
                    return (
                        <tr
                            key={calco.id}
                            className={calco.flag_erro ? "  erro " : ""}
                        >
                            <td className="flex flex-row">
                                <TooltipErro item={calco} />
                                <Controller
                                    name={`calcos.${index}.dimensao1`}
                                    control={control}
                                    defaultValue={calco.dimensao1}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onChange={(e) => {
                                                let valor =
                                                    e.target.value.toUpperCase();
                                                setValue(
                                                    `calcos.${index}.dimensao1`,
                                                    valor,
                                                );
                                                const simbolos = [
                                                    "-",
                                                    "+",
                                                    ".",
                                                ];

                                                for (let simbolo of simbolos) {
                                                    if (valor === simbolo) {
                                                        e.preventDefault();

                                                        let parafuso =
                                                            preenchimento
                                                                ?.parafusos[
                                                                simbolos.indexOf(
                                                                    simbolo,
                                                                )
                                                            ];
                                                        if (!parafuso) {
                                                            continue;
                                                        }

                                                        let qualidade =
                                                            preenchimento?.qualidade_calcos;
                                                        let bitola =
                                                            parafuso.parafuso_bitola;
                                                        if (qualidade) {
                                                            setValue(
                                                                `calcos.${index}.qualidade`,
                                                                qualidade,
                                                            );
                                                        }
                                                        setValue(
                                                            `calcos.${index}.dimensao1`,
                                                            bitola,
                                                        );
                                                        proximaCelula(e);
                                                        return;
                                                    }
                                                }
                                            }}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            onBlur={(e) => {
                                                if (
                                                    index ===
                                                        fieldCalcos.fields
                                                            .length -
                                                            1 &&
                                                    e.target.value !== ""
                                                ) {
                                                    fieldCalcos.append(
                                                        {},
                                                        { shouldFocus: false },
                                                    );
                                                }
                                            }}
                                            type="text"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`calcos.${index}.dimensao2`}
                                    control={control}
                                    defaultValue={calco.dimensao2}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            required={
                                                !!watch(
                                                    `calcos.${index}.dimensao1`,
                                                )
                                            }
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            type="text"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`calcos.${index}.quantidade`}
                                    control={control}
                                    defaultValue={calco.quantidade}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            min={
                                                !!watch(
                                                    `calcos.${index}.dimensao1`,
                                                )
                                                    ? 1
                                                    : ""
                                            }
                                            required={
                                                !!watch(
                                                    `calcos.${index}.dimensao1`,
                                                )
                                            }
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            soNumero={true}
                                            type="text"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`calcos.${index}.qualidade`}
                                    control={control}
                                    defaultValue={calco.qualidade}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            required={
                                                !!watch(
                                                    `calcos.${index}.dimensao1`,
                                                )
                                            }
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            type="text"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`calcos.${index}.rev`}
                                    control={control}
                                    defaultValue={calco.rev}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            required={
                                                !!watch(
                                                    `calcos.${index}.dimensao1`,
                                                )
                                            }
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            disabled={!campoRevisao}
                                            type="text"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
