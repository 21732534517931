import React from "react";
import { Controller } from "react-hook-form";
import InputLm from "pages/admin/cliente/projetos/subparte/components/input-lm";
import {
    proximaCelula,
    TooltipErro,
} from "pages/admin/cliente/projetos/subparte/components/utils";

export default function TabelaPalnuts({
    fieldPalnuts,
    preenchimento,
    campoRevisao,
    form,
}) {
    const { control, watch, setValue } = form;

    const deletarLinha = (e, index) => {
        e.preventDefault();

        const fieldset = e.target.closest("fieldset");
        if (fieldset && fieldset.disabled) {
            return;
        }

        let confirmacao = window.confirm("Deseja deletar a linha selecionada?");
        if (!confirmacao) {
            return;
        }

        if (fieldPalnuts.fields.length > 1) {
            fieldPalnuts.remove(index);
        } else {
            alert("Não é possível remover a última linha");
        }
    };

    return (
        <table className="table-fixed">
            <thead className="header-sticky">
                <tr>
                    <th
                        colSpan={1}
                        className="bg-blue-600 text-white border border-gray-900 font-medium"
                    >
                        PALNUT
                    </th>
                    <th colSpan={3} />
                </tr>
                <tr>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Bitola
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Quantidade
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Qualidade
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Revisão
                    </th>
                </tr>
            </thead>
            <tbody>
                {fieldPalnuts.fields.map((palnut, index) => {
                    let autoPreenchimento = palnut.flag_auto_preenchimento;
                    if (!autoPreenchimento) {
                        return <React.Fragment key={palnut.id} />;
                    }

                    return (
                        <tr key={palnut.id} className="auto-preenchimento">
                            <td className="flex flex-row">
                                <InputLm
                                    disabled={true}
                                    defaultValue={palnut.dimensao1}
                                    type="text"
                                    className="text-center w-full input-tabela-sm auto-preenchimento"
                                />
                            </td>
                            <td>
                                <InputLm
                                    disabled={true}
                                    defaultValue={palnut.quantidade}
                                    type="text"
                                    className="text-center w-full input-tabela-sm auto-preenchimento"
                                />
                            </td>
                            <td>
                                <InputLm
                                    disabled={true}
                                    defaultValue={palnut.qualidade}
                                    type="text"
                                    className="text-center w-full input-tabela-sm auto-preenchimento"
                                />
                            </td>
                            <td>
                                <InputLm
                                    disabled={true}
                                    defaultValue={palnut.rev}
                                    type="text"
                                    className="text-center w-full input-tabela-sm auto-preenchimento"
                                />
                            </td>
                        </tr>
                    );
                })}

                {fieldPalnuts.fields.map((palnut, index) => {
                    let autoPreenchimento = palnut.flag_auto_preenchimento;
                    if (autoPreenchimento) {
                        return <React.Fragment key={palnut.id} />;
                    }

                    return (
                        <tr
                            key={palnut.id}
                            className={palnut.flag_erro ? "erro" : ""}
                        >
                            <td className="flex flex-row">
                                <TooltipErro item={palnut} />
                                <Controller
                                    name={`palnuts.${index}.dimensao1`}
                                    control={control}
                                    defaultValue={palnut.tipo}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            onChange={(e) => {
                                                let valor =
                                                    e.target.value.toUpperCase();
                                                setValue(
                                                    `palnuts.${index}.dimensao1`,
                                                    valor,
                                                );
                                                const simbolos = [
                                                    "-",
                                                    "+",
                                                    ".",
                                                ];
                                                for (let simbolo of simbolos) {
                                                    if (valor === simbolo) {
                                                        e.preventDefault();

                                                        let parafuso =
                                                            preenchimento
                                                                ?.parafusos[
                                                                simbolos.indexOf(
                                                                    simbolo,
                                                                )
                                                            ];
                                                        if (!parafuso) {
                                                            continue;
                                                        }

                                                        let qualidade =
                                                            parafuso.parafuso_qualidade;
                                                        let bitola =
                                                            parafuso.parafuso_bitola;
                                                        setValue(
                                                            `palnuts.${index}.qualidade`,
                                                            qualidade,
                                                        );
                                                        setValue(
                                                            `palnuts.${index}.dimensao1`,
                                                            bitola,
                                                        );
                                                        proximaCelula(e);
                                                        return;
                                                    }
                                                }

                                                let parafuso =
                                                    preenchimento?.parafusos?.find(
                                                        (x) =>
                                                            x.parafuso_bitola ===
                                                            valor,
                                                    );
                                                let qualidade =
                                                    parafuso?.palnuts_qualidade;
                                                if (parafuso && qualidade) {
                                                    setValue(
                                                        `palnuts.${index}.qualidade`,
                                                        parafuso?.palnuts_qualidade,
                                                    );
                                                    proximaCelula(e);
                                                }
                                            }}
                                            onBlur={(e) => {
                                                if (
                                                    index ===
                                                        fieldPalnuts.fields
                                                            .length -
                                                            1 &&
                                                    e.target.value !== ""
                                                ) {
                                                    fieldPalnuts.append(
                                                        {},
                                                        { shouldFocus: false },
                                                    );
                                                }
                                            }}
                                            type="text"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`palnuts.${index}.quantidade`}
                                    control={control}
                                    defaultValue={palnut.quantidade}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            min={
                                                !!watch(
                                                    `palnuts.${index}.dimensao1`,
                                                )
                                                    ? 1
                                                    : ""
                                            }
                                            required={
                                                !!watch(
                                                    `palnuts.${index}.dimensao1`,
                                                )
                                            }
                                            soNumero={true}
                                            type="text"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`palnuts.${index}.qualidade`}
                                    control={control}
                                    defaultValue={palnut.qualidade}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            required={
                                                !!watch(
                                                    `palnuts.${index}.dimensao1`,
                                                )
                                            }
                                            type="text"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`palnuts.${index}.rev`}
                                    control={control}
                                    defaultValue={palnut.rev}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            disabled={!campoRevisao}
                                            type="text"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
