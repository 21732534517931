import { useParams } from "react-router";
import React from "react";
import { route, urls } from "urls";
import AdminLayout from "layout/admin-layout";
import ProjetoLayout from "layout/projeto-layout";
import Breadcrumbs from "layout/admin/breadcrumbs";
import Panel from "layout/form/panel";
import { Link } from "react-router-dom";

export default function ProjetoImportarPage() {
    const { id_cliente, id_projeto } = useParams();

    return (
        <AdminLayout>
            <ProjetoLayout active="importar">
                <Breadcrumbs
                    title="Importar Projeto"
                    breadcrumbs={[
                        {
                            label: "Clientes",
                            url: route(urls.web.admin.cliente.listar),
                        },
                        {
                            label: "Projetos",
                            url: route(urls.web.admin.cliente.projeto.listar, {
                                id_cliente: id_cliente,
                            }),
                        },
                        {
                            label: "Importar",
                            url: "",
                        },
                    ]}
                />

                <div className="mx-5 mt-3">
                    <Panel title="Importar">
                        <div className="d-flex flex-col gap-4 m-5">
                            <li className="text-blue-600">
                                <Link
                                    to={route(
                                        urls.web.admin.cliente.projeto.importar
                                            .lm_cliente,
                                        {
                                            id_cliente,
                                            id_projeto,
                                        },
                                    )}
                                >
                                    Lista de Materiais do cliente - XLSX
                                </Link>
                            </li>
                            <li className="text-blue-600">
                                <Link
                                    to={route(
                                        urls.web.admin.cliente.projeto.importar
                                            .lm_programa,
                                        {
                                            id_cliente,
                                            id_projeto,
                                        },
                                    )}
                                >
                                    Programa de lista - XLSM
                                </Link>
                            </li>
                        </div>
                    </Panel>
                </div>
            </ProjetoLayout>
        </AdminLayout>
    );
}
