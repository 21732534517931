import axios from "axios";
import { getToken } from "./token-auth";

const api = axios.create({
    method: "GET",
    mode: "no-cors",
    crossdomain: true,
    headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        common: { Authorization: `Token ${getToken()}` },
    },
    baseURL: process.env.REACT_APP_API_URL,
});

export const multipartApi = axios.create({
    method: "GET",
    mode: "no-cors",
    crossdomain: true,
    headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
        common: { Authorization: `Token ${getToken()}` },
    },
    baseURL: process.env.REACT_APP_API_URL,
});

export default api;
