import { Controller } from "react-hook-form";
import React, { useEffect, useState } from "react";
import {
    proximaCelula,
    TooltipErro,
} from "pages/admin/cliente/projetos/subparte/components/utils";
import InputLm from "pages/admin/cliente/projetos/subparte/components/input-lm";

export default function TabelaPerfis({
    fieldPerfil,
    campoOperacoes,
    camposDesabilitar,
    itensCompletar,
    preenchimento,
    campoRevisao,
    form,
}) {
    const { control, getValues, watch, setValue } = form;
    const [posicoesRepetidas, setPosicoesRepetidas] = useState([]);

    let perfisDados = fieldPerfil.fields;

    useEffect(() => {
        let posicoes_ = [];
        for (let perfil of perfisDados) {
            if (!perfil.posicao) {
                continue;
            }

            if (itensCompletar[perfil.posicao]) {
                posicoes_.push(perfil.posicao);
            }
        }
        setPosicoesRepetidas(posicoes_);
    }, [itensCompletar]);

    const onCtrolQ = (index) => {
        let values = getValues(`perfis.${index}`);
        let valuesNovo = { ...values };
        valuesNovo["posicao"] = "";
        valuesNovo["complemento"] = "";
        const currentLength = fieldPerfil.fields.length;
        fieldPerfil.insert(currentLength - 1, valuesNovo);
    };

    const deletarLinha = (e, index) => {
        e.preventDefault();

        const fieldset = e.target.closest("fieldset");
        if (fieldset && fieldset.disabled) {
            return;
        }

        let confirmacao = window.confirm("Deseja deletar a linha selecionada?");
        if (!confirmacao) {
            return;
        }

        if (fieldPerfil.fields.length > 1) {
            fieldPerfil.remove(index);
        } else {
            alert("Não é possível remover a última linha");
        }
    };

    return (
        <table className="table-fixed w-full">
            <thead className="font-medium header-sticky">
                <tr>
                    <th className="bg-blue-600 text-white border border-gray-900 font-medium">
                        Perfis
                    </th>
                    <th colSpan={2} />
                    <th
                        className="bg-blue-300 border border-gray-900 font-medium"
                        colSpan={3}
                    >
                        Dimensões
                    </th>
                    <th colSpan={3} />
                    <th
                        colSpan={6}
                        className="bg-blue-300 border border-gray-900 font-medium"
                    >
                        Operações
                    </th>
                </tr>
                <tr>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Posição
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Complemento
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Tipo de Perfil
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        A1
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        A2
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        A3
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Qualidade
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Comprimento
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Quantidade
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Recorte
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Dobra
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Chanfro
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Esquerda
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Degrau
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        CJS
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        REV
                    </th>
                </tr>
            </thead>

            <tbody>
                {fieldPerfil.fields.map((perfil, index) => {
                    let tipo = watch(`perfis.${index}.tipo`);
                    let dimensao2 = watch(`perfis.${index}.dimensao2`);

                    let getCamposDesabilitados = () => {
                        // so tipo
                        let campos = camposDesabilitar.filter(
                            (x) => x.tipo === tipo,
                        );
                        if (campos.length === 1) {
                            return campos[0].desabilita;
                        }

                        // TB com dimensao
                        let campos2 = camposDesabilitar.filter(
                            (x) =>
                                x.tipo === tipo &&
                                x.dimensao2 === dimensao2 &&
                                dimensao2 !== undefined,
                        );
                        if (campos2.length === 1) {
                            return campos2[0].desabilita;
                        }

                        // com tipo e sem dimensao definida
                        let campos3 = camposDesabilitar.filter(
                            (x) => x.tipo === tipo && x.dimensao2 === undefined,
                        );
                        if (campos3.length === 1) {
                            return campos3[0].desabilita;
                        }
                        return [];
                    };

                    let camposDesabilitados = getCamposDesabilitados();
                    let flagRepetido =
                        posicoesRepetidas.includes(perfil.posicao) &&
                        perfil.posicao;

                    return (
                        <tr
                            key={perfil.id}
                            className={perfil.flag_erro ? " erro " : ""}
                        >
                            <td className={"flex flex-row"}>
                                <TooltipErro item={perfil} />
                                <Controller
                                    name={`perfis.${index}.posicao`}
                                    control={control}
                                    defaultValue={perfil?.posicao ?? ""}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onCtrolQ={() => onCtrolQ(index)}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            minusculo={true}
                                            onInput={(event) => {
                                                let valueOriginal =
                                                    event.target.value;
                                                let value = event.target.value;

                                                // Substituir caracteres
                                                value = value.replace(
                                                    /\+/g,
                                                    "H",
                                                );
                                                value = value.replace(
                                                    /-/g,
                                                    "G",
                                                );
                                                event.target.value = value;
                                                if (valueOriginal !== value) {
                                                    proximaCelula(event);
                                                }
                                            }}
                                            onBlur={(e) => {
                                                if (
                                                    index ===
                                                        fieldPerfil.fields
                                                            .length -
                                                            1 &&
                                                    e.target.value !== ""
                                                ) {
                                                    fieldPerfil.append(
                                                        {},
                                                        { shouldFocus: false },
                                                    );
                                                }
                                                let value = e.target.value;
                                                let itemCompletar =
                                                    itensCompletar[value];

                                                if (itemCompletar) {
                                                    setValue(
                                                        `perfis.${index}.qualidade`,
                                                        itemCompletar.qualidade,
                                                    );
                                                    setValue(
                                                        `perfis.${index}.tipo`,
                                                        itemCompletar.tipo,
                                                    );
                                                    setValue(
                                                        `perfis.${index}.dimensao1`,
                                                        itemCompletar.dimensao1,
                                                    );
                                                    setValue(
                                                        `perfis.${index}.dimensao2`,
                                                        itemCompletar.dimensao2,
                                                    );
                                                    setValue(
                                                        `perfis.${index}.dimensao3`,
                                                        itemCompletar.dimensao3,
                                                    );
                                                    setValue(
                                                        `perfis.${index}.dimensao4`,
                                                        itemCompletar.dimensao4,
                                                    );
                                                    setValue(
                                                        `perfis.${index}.complemento`,
                                                        itemCompletar.complemento,
                                                    );
                                                    setValue(
                                                        `perfis.${index}.rec`,
                                                        itemCompletar.rec,
                                                    );
                                                    setValue(
                                                        `perfis.${index}.dob`,
                                                        itemCompletar.dob,
                                                    );
                                                    setValue(
                                                        `perfis.${index}.chan`,
                                                        itemCompletar.chan,
                                                    );
                                                    setValue(
                                                        `perfis.${index}.esq`,
                                                        itemCompletar.esq,
                                                    );
                                                    setValue(
                                                        `perfis.${index}.cjs`,
                                                        itemCompletar.cjs,
                                                    );
                                                    setValue(
                                                        `perfis.${index}.deg`,
                                                        itemCompletar.deg,
                                                    );

                                                    if (
                                                        !flagRepetido &&
                                                        perfil.posicao
                                                    ) {
                                                        setPosicoesRepetidas([
                                                            ...posicoesRepetidas,
                                                            perfil.posicao,
                                                        ]);
                                                    }
                                                } else {
                                                    setPosicoesRepetidas(
                                                        posicoesRepetidas.filter(
                                                            (x) =>
                                                                x !==
                                                                perfil.posicao,
                                                        ),
                                                    );
                                                }
                                            }}
                                            type="text"
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                setValue(
                                                    `perfis.${index}.posicao`,
                                                    value,
                                                );

                                                if (
                                                    String(value).includes("H")
                                                ) {
                                                    setValue(
                                                        `perfis.${index}.qualidade`,
                                                        "H",
                                                    );
                                                }
                                                if (
                                                    String(value).includes("G")
                                                ) {
                                                    setValue(
                                                        `perfis.${index}.qualidade`,
                                                        "G",
                                                    );
                                                }
                                            }}
                                            className={
                                                "text-center w-full input-tabela-sm "
                                            }
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`perfis.${index}.complemento`}
                                    control={control}
                                    defaultValue={perfil?.complemento ?? ""}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            onCtrolQ={() => onCtrolQ(index)}
                                            onInput={(event) => {
                                                let value =
                                                    event.target.value.toUpperCase();
                                                let valueOriginal =
                                                    event.target.value.toUpperCase();
                                                value = value.replace(
                                                    /\+/g,
                                                    "D/E",
                                                );
                                                value = value.replace(
                                                    /-/g,
                                                    "DH/EH",
                                                );
                                                value = value.replace(
                                                    /\./g,
                                                    "DG/EG",
                                                );
                                                event.target.value = value;
                                                if (valueOriginal !== value) {
                                                    proximaCelula(event);
                                                }
                                            }}
                                            type="text"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`perfis.${index}.tipo`}
                                    control={control}
                                    defaultValue={perfil?.tipo ?? ""}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onCtrolQ={() => onCtrolQ(index)}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            required={
                                                !!watch(
                                                    `perfis.${index}.posicao`,
                                                )
                                            }
                                            onInput={(event) => {
                                                let value =
                                                    event.target.value.toUpperCase();
                                                let valueOriginal =
                                                    event.target.value.toUpperCase();
                                                value = value.replace(
                                                    /\+/g,
                                                    "L",
                                                );
                                                value = value.replace(
                                                    /\./g,
                                                    "PL",
                                                );
                                                event.target.value = value;
                                                if (valueOriginal !== value) {
                                                    proximaCelula(event);
                                                }
                                            }}
                                            type="text"
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                value =
                                                    String(value).toUpperCase();
                                                setValue(
                                                    `perfis.${index}.tipo`,
                                                    value,
                                                );
                                                let qualidade = getValues(
                                                    `perfis.${index}.qualidade`,
                                                );

                                                let qualidadePreencher =
                                                    preenchimento?.perfis?.find(
                                                        (x) => x.tipo === value,
                                                    )?.qualidade;
                                                if (
                                                    qualidadePreencher &&
                                                    !["G", "H"].includes(
                                                        qualidade,
                                                    )
                                                ) {
                                                    setValue(
                                                        `perfis.${index}.qualidade`,
                                                        qualidadePreencher,
                                                    );
                                                }
                                            }}
                                            className={
                                                "text-center w-full input-tabela-sm " +
                                                (flagRepetido
                                                    ? " input-repetido "
                                                    : "")
                                            }
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`perfis.${index}.dimensao1`}
                                    control={control}
                                    defaultValue={perfil?.dimensao1 ?? ""}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            disabled={
                                                camposDesabilitados.indexOf(
                                                    "A1",
                                                ) >= 0
                                            }
                                            required={
                                                !!watch(
                                                    `perfis.${index}.posicao`,
                                                ) &&
                                                camposDesabilitados.indexOf(
                                                    "A1",
                                                ) < 0
                                            }
                                            onCtrolQ={() => onCtrolQ(index)}
                                            type="text"
                                            className={
                                                "text-center w-full input-tabela-sm" +
                                                (flagRepetido
                                                    ? " input-repetido "
                                                    : "")
                                            }
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`perfis.${index}.dimensao2`}
                                    control={control}
                                    defaultValue={perfil?.dimensao2 ?? ""}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            disabled={
                                                camposDesabilitados.indexOf(
                                                    "A2",
                                                ) >= 0
                                            }
                                            required={
                                                !!watch(
                                                    `perfis.${index}.posicao`,
                                                ) &&
                                                camposDesabilitados.indexOf(
                                                    "A2",
                                                ) < 0
                                            }
                                            onCtrolQ={() => onCtrolQ(index)}
                                            type="text"
                                            className={
                                                "text-center w-full input-tabela-sm" +
                                                (flagRepetido
                                                    ? " input-repetido "
                                                    : "")
                                            }
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`perfis.${index}.dimensao3`}
                                    control={control}
                                    defaultValue={perfil?.dimensao3 ?? ""}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            minusculo={true}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            disabled={
                                                camposDesabilitados.indexOf(
                                                    "A3",
                                                ) >= 0
                                            }
                                            required={
                                                !!watch(
                                                    `perfis.${index}.posicao`,
                                                ) &&
                                                camposDesabilitados.indexOf(
                                                    "A3",
                                                ) < 0
                                            }
                                            onCtrolQ={() => onCtrolQ(index)}
                                            type="text"
                                            className={
                                                "text-center w-full input-tabela-sm" +
                                                (flagRepetido
                                                    ? " input-repetido "
                                                    : "")
                                            }
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`perfis.${index}.qualidade`}
                                    control={control}
                                    defaultValue={perfil?.qualidade ?? ""}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onFocus={(e) => {
                                                if (
                                                    String(
                                                        e.relatedTarget?.name ??
                                                            "",
                                                    ).includes("dimensao2") &&
                                                    e.target.value !== ""
                                                ) {
                                                    proximaCelula(e, 1);
                                                }
                                            }}
                                            required={
                                                !!watch(
                                                    `perfis.${index}.posicao`,
                                                )
                                            }
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            onCtrolQ={() => onCtrolQ(index)}
                                            type="text"
                                            className={
                                                "text-center w-full input-tabela-sm" +
                                                (flagRepetido
                                                    ? " input-repetido "
                                                    : "")
                                            }
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`perfis.${index}.dimensao4`}
                                    control={control}
                                    defaultValue={perfil?.dimensao4 ?? ""}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            required={
                                                !!watch(
                                                    `perfis.${index}.posicao`,
                                                )
                                            }
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            onCtrolQ={() => onCtrolQ(index)}
                                            type="text"
                                            className={
                                                "text-center w-full input-tabela-sm" +
                                                (flagRepetido
                                                    ? " input-repetido "
                                                    : "")
                                            }
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`perfis.${index}.quantidade`}
                                    control={control}
                                    defaultValue={perfil?.quantidade ?? ""}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            required={
                                                !!watch(
                                                    `perfis.${index}.posicao`,
                                                )
                                            }
                                            min={
                                                !!watch(
                                                    `perfis.${index}.posicao`,
                                                )
                                                    ? 1
                                                    : ""
                                            }
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            type="text"
                                            soNumero={true}
                                            onCtrolQ={() => onCtrolQ(index)}
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`perfis.${index}.rec`}
                                    control={control}
                                    defaultValue={perfil?.rec ?? ""}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            onCtrolQ={() => onCtrolQ(index)}
                                            disabled={!campoOperacoes}
                                            type="text"
                                            className={
                                                "text-center w-full input-tabela-sm" +
                                                (flagRepetido
                                                    ? " input-repetido "
                                                    : "")
                                            }
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`perfis.${index}.dob`}
                                    control={control}
                                    defaultValue={perfil?.dob ?? ""}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            onCtrolQ={() => onCtrolQ(index)}
                                            disabled={!campoOperacoes}
                                            type="text"
                                            className={
                                                "text-center w-full input-tabela-sm" +
                                                (flagRepetido
                                                    ? " input-repetido "
                                                    : "")
                                            }
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`perfis.${index}.chan`}
                                    control={control}
                                    defaultValue={perfil?.chan ?? ""}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            onCtrolQ={() => onCtrolQ(index)}
                                            disabled={!campoOperacoes}
                                            type="text"
                                            className={
                                                "text-center w-full input-tabela-sm" +
                                                (flagRepetido
                                                    ? " input-repetido "
                                                    : "")
                                            }
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`perfis.${index}.esq`}
                                    control={control}
                                    defaultValue={perfil?.esq ?? ""}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            disabled={!campoOperacoes}
                                            onCtrolQ={() => onCtrolQ(index)}
                                            type="text"
                                            className={
                                                "text-center w-full input-tabela-sm" +
                                                (flagRepetido
                                                    ? " input-repetido "
                                                    : "")
                                            }
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`perfis.${index}.deg`}
                                    control={control}
                                    defaultValue={perfil?.deg ?? ""}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            onCtrolQ={() => onCtrolQ(index)}
                                            type="text"
                                            disabled={!campoOperacoes}
                                            className={
                                                "text-center w-full input-tabela-sm" +
                                                (flagRepetido
                                                    ? " input-repetido "
                                                    : "")
                                            }
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`perfis.${index}.cjs`}
                                    control={control}
                                    defaultValue={perfil?.cjs ?? ""}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            onCtrolQ={() => onCtrolQ(index)}
                                            type="text"
                                            disabled={!campoOperacoes}
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`perfis.${index}.rev`}
                                    control={control}
                                    defaultValue={perfil?.rev ?? ""}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            onCtrolQ={() => onCtrolQ(index)}
                                            disabled={!campoRevisao}
                                            type="text"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
