import { include, reverse } from "named-urls";

export const route = reverse;
export const urls = {
    web: include("", {
        auth: include("", {
            login: "login",
        }),
        admin: include("admin", {
            ferramentas: include("ferramentas", {
                importar_lm: "importar-lm",
            }),
            logout: "logout",
            usuario: include("usuario", {
                listar: "",
                meu_perfil: "meu-perfil",
                editar: ":id_usuario/editar",
                criar: "criar",
            }),
            cliente: include("cliente", {
                listar: "",
                editar: ":id_cliente/editar",
                criar: "criar",
                projeto: include(":id_cliente/projeto", {
                    listar: "",
                    criar: "criar",
                    editar: ":id_projeto/editar",
                    erros: ":id_projeto/erros",
                    importar: include(":id_projeto/importar", {
                        listar: "",
                        lm_cliente: "lm-cliente",
                        lm_programa: "lm-programa",
                    }),
                    preenchimento: ":id_projeto/preenchimento",
                    exportar: include(":id_projeto/exportar", {
                        listar: "",
                        conferencia: "conferencia",
                        lm_excel: "lm-excel",
                        lp_excel: "lp-excel",
                        lm_txt: "lm-txt",
                    }),
                    parte: include(":id_projeto/parte", {
                        listar: "",
                        criar: "criar",
                        editar: ":id_parte/editar",
                    }),
                    subparte: include(":id_projeto/subparte", {
                        listar: "",
                        criar: "criar",
                        editar: ":id_subparte/editar",
                        itens: ":id_subparte/itens",
                    }),
                }),
            }),
        }),
    }),
    api: include("", {
        admin: include("admin", {
            auth: include("auth", {
                login: "login",
                me: "me",
            }),
            verificar_acesso: "verificar-acesso",
            usuario: include("usuario", {
                index: "",
                edit: ":id_usuario",
            }),
            cliente: include("cliente", {
                index: "",
                edit: ":id_cliente",
                projeto: include(":id_cliente/projeto", {
                    index: "",
                    edit: ":id_projeto",
                    importar: ":id_projeto/importar",
                    importar_progama_lm: ":id_projeto/importar-programa-lm",
                    erros: ":id_projeto/erros",
                    preenchimento: ":id_projeto/preenchimento",
                    parte: include(":id_projeto/parte", {
                        index: "",
                        edit: ":id_parte",
                        alterar_ordem: ":id_parte/alterar-ordem",
                    }),
                    exportar: include(":id_projeto/exportar", {
                        conferencia: "conferencia",
                        lm_excel: "lm-excel",
                        lp_excel: "lp-excel",
                        lm_txt: "lm-txt",
                    }),
                    subparte: include(":id_projeto/subparte", {
                        index: "",
                        edit: ":id_subparte",
                        itens: ":id_subparte/itens",
                        alterar_ordem: ":id_subparte/alterar-ordem",
                        modo_edicao: ":id_subparte/modo-edicao",
                        salvar_rascunho: ":id_subparte/salvar-rascunho",
                    }),
                }),
            }),
        }),
    }),
};
