import api, { multipartApi } from "../utils/api";
import { route, urls } from "urls";

export default class ProjetoImportar {
    constructor(id_cliente, id_projeto = 0) {
        this.url_params = { id_cliente: id_cliente, id_projeto: id_projeto };
    }

    async save(values) {
        const url = route(
            urls.api.admin.cliente.projeto.importar,
            this.url_params,
        );
        const { data } = await multipartApi.post(url, values);
        return data;
    }

    async saveProgramaLm(values) {
        const url = route(
            urls.api.admin.cliente.projeto.importar_progama_lm,
            this.url_params,
        );
        const { data } = await multipartApi.post(url, values);
        return data;
    }
}
